html {
    box-sizing: border-box;
    font-size: $global-font-size;
    color: $cl-body-font-color;
    // background-color:$cl-black;
    // background-image: linear-gradient(180deg, $cl-body-background $globalheaderheight-small, transparent $globalheaderheight-small);
    // background-color: $cl-gray-xdark;
    // @include breakpoint(60em) {
    //     font-size: 15px;
    // }
    @include breakpoint(80em) {
        font-size: 16px;
    }
    @include breakpoint(112em) {
        font-size: 18px;
    }
}



// Set box-sizing globally to handle padding and border widths
*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    position: relative;
    margin: 0;
    padding: 0;
    font-family: $fontsans;
    font-weight: $fw-normal;
    line-height: 1.4;
    font-feature-settings: "lnum" 1;
    -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
    -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes

    &.is-frozen {
        overflow: hidden;
    }
}






img {
    // Get rid of gap under images by making them display: inline-block; by default
    display: inline-block;
    vertical-align: middle;

    // Grid defaults to get images and embeds to work properly
    max-width: 100%;
    height: auto;
    -ms-interpolation-mode: bicubic;
    image-rendering: -webkit-optimize-contrast;
  }

.screen-reader, .screen-reader-text {
    position: absolute!important;
    clip: rect(1px,1px,1px,1px);
}


.mapcanvas {
    height: 0;
    padding-bottom: 112%;
    width: 100%;
}
