@media screen and (max-width: 600px) {
    #wpadminbar {
        position: fixed !important;
    }
}

.offcanvas {
    position: relative;
    background-color: $cl-black;
    &.sidebar-is-open {
        overflow: hidden;
        @include breakpoint($bp-tablet) {
            overflow: initial;
        }

        .offcanvas__left  {
            transform: translateX(0);
        }
        .offcanvas__body  {

        }

        .offcanvas__body__overlay {
            z-index: 0;
            visibility: visible;
            opacity: 1;
        }


    }
    > * {
        transition: transform .2s ease-in;
    }
}

.offcanvas__left {
    z-index: 2;
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    width:#{$globalsidebarwidth-small};
    // height: 100%;
    overflow-y: auto;
    background-color: $cl-black;
    color: #fff;
    border-right: 1px solid rgba(#fff,.025);
    transition: transform .2s ease-in;
    transform: translateX(#{-$globalsidebarwidth-small});
    backface-visibility: hidden;

    @include breakpoint($bp-tablet) {
        width:#{$globalsidebarwidth};
        transform: translateX(0);
        z-index: 0;

    }
    body.admin-bar & {
        padding-top: 32px !important;
        @media screen and (max-width: 782px) {
            padding-top: 46px !important;
        }
    }
}

.offcanvas__body {
    position: relative;
    z-index: 1;
    backface-visibility: hidden;
    background-color: $cl-body-background;
    color: $cl-body-font-color;
    // margin-right:15%;
    @include breakpoint($bp-tablet) {
        margin-left: $globalsidebarwidth;
    }
}

.offcanvas__body__overlay {
    background-color: #fff;
    position: absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    z-index: -1;
    display: block;
    opacity: 0;
    transition: opacity .4s linear;
    visibility: hidden;
    mix-blend-mode: saturation;

}
.pagesidebar {
    height:100%;
    padding: 0 0 2rem;
    text-align: center;
    overflow-x: hidden;
    // border-right: 1px solid rgba(#fff,.025);


}

.pagesidebar__brand {
    display: flex;

    height:$globalheaderheight-small;
    align-items: center;

    &:hover, &:focus {
        img {
            opacity: 1;
        }
    }
    img {
        height:63%;
        margin:0 auto;
        opacity: .75;


    }

    @media screen and (max-height: 36em) and (max-width: 767px ){
        display: none;
    }

    @include breakpoint($bp-tablet) {
        // height:$globalheaderheight-small;
        display: flex;
    }

    @include breakpoint($bp-large) {
        height:$globalheaderheight;
    }
}

.pagesidebar__close {
    position: absolute;
    z-index: 1;
    font-size: 2rem;
    text-align: center;
    bottom:calc(.5rem + 2.5%);
    left:50%;
    transform:translateX(-50%);
    display: inline-block;
    // border-radius: 50%;
    border:1px solid rgba(#fff,.05);
    // box-shadow: 0 0 .5rem 0 rgba(#fff,.125);
    color:$cl-gray-light;
    background-color: rgba(#000,.75);
    line-height: 1;
    height:1.125em;
    width:1.125em;

    @include breakpoint($bp-tablet) {
        display: none;
    }

    svg {
        width:100%;
        height:100%;
        padding:33.333%;
        fill: $cl-primary;
    }

    .mobilenavpanel-is-open & {
        display: none;
    }
}

.pagesidebar__bottom {
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    padding:10% .5rem;
    // background-color: $cl-primary;
    @media screen and (max-height: 38em){
        display: none;
    }
    @media screen and (max-height: 45em) and (min-width: 768px ){
        display: none;
    }

    @media screen and (max-width: 767px ){
        display: none;
    }

}
.pagesidebar__slogan {
    max-height: #{$globalheaderheight - 1rem};
    opacity: .4;
}


.sidebartoggler {
    display: inline-flex;
    position: fixed;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    z-index: 998;
    right:0;
    top:calc(50vh - 3rem);
    width:3.25rem;
    height:6rem;
    // height:4rem;
    background-color: rgba(#000,.75);
    text-align: center;
    line-height: 1;
    padding:.75rem 0;
    border:1px solid rgba(#fff,.125);
    border-right:0;

    svg {
        // padding:0 .4333rem;
        width:60.666%;
        height:2rem;
        fill: #fff;
        filter: brightness(2);
        // transform: scaleX(-1);
    }

    svg + svg {
        fill: $cl-primary;
        height:.75rem;
        width:33.333%;
        filter:none;
    }

    .sidebar-is-open & {
        // filter:brightness(4)
    }

    @include breakpoint('tablet') {
        display: none;
    }

}
