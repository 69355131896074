.postswiper {
    margin-top: rem-calc($layout-gutter-width*1.5);
    width:92.5%;
    @include breakpoint($bp-medium) {
        width:26rem
    }
    @include breakpoint($bp-large) {
        width:100%
    }


    &.slick-dotted.slick-slider {
        margin-bottom: rem-calc($layout-gutter-width);
    }
    .slick-slide {
        margin: 0 rem-calc($layout-gutter-width/4);

        @include breakpoint($bp-medium) {
            margin: 0 rem-calc($layout-gutter-width/2);
        }
    }

    .slick-list {
        overflow: visible;
        margin: 0 rem-calc(-$layout-gutter-width/4);
        @include breakpoint($bp-medium) {

            margin: 0 rem-calc(-$layout-gutter-width/2);
        }
        @include breakpoint($bp-large) {
            overflow: hidden;
        }
    }

    .slick-track {
        // position: relative;
        // background-color: red;
    }
    .slick-dots {
        position: absolute;
        bottom: rem-calc(-$layout-gutter-width*2);
        text-align: left;
        @include breakpoint($bp-large) {
            text-align: center;
        }
    }

    .postcard {
        min-height: 35rem;
        @include breakpoint($bp-xlarge) {
            min-height: 34rem;
        }
    }
}
