.ass {
    line-height: 1;
    font-size: .666rem;
    text-transform: uppercase;
    text-align: center;
    font-weight: $fw-black;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 0 2px;
    background-color: $cl-primary;
    color:$cl-gray-xlight;
    // padding:.25em 0 .125em;
    // width:min-content;
    white-space: nowrap;
    max-width: min-content;
    // margin:0 auto;
    //  border-radius: 1px;
    border:3px solid $cl-gray-xlight;
    box-shadow: 0 0 .25em 0 rgba(#000,.75);

    @include breakpoint($bp-large) {
        font-size: .75rem;
    }

    @include breakpoint($bp-xlarge) {
        // font-size: 1.125rem;
    }

    span {
        display: block;
    }

    &:hover, &:focus {
        background-color: darken($cl-primary,10%);
        color:#fff;;
    }

}

.ass__one {
    grid-column: 1 / span 2;
    padding: .5em .25em .4666em;
    font-size: .9375em;
}

.ass__247 {
    font-size: 2.5666em;
    font-weight: $fw-bold;
    padding: .125em .125em;
    color: $cl-gray-dark;
    background-color: $cl-gray-xlight;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.ass__viass {
    display: block;
    color: $cl-gray-dark;
    background-color: $cl-gray-xlight;
    padding:.125em .333em;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.ass__viarent {
    font-size: 1.5em;
}
.ass__assistance {
    font-size: 1em;
}

.ass__phone {
    grid-column: 1 / span 2;
    font-size: 1.666em;
    padding:.125em .125em;




}
