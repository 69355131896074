:root {
    --owncolor: #{$cl-body-font-color};
}

.advcard--1 { --owncolor: #{$c-red}; }
.advcard--2 { --owncolor: #{$c-green}; }
.advcard--3 { --owncolor: #{$c-blue}; }
.advcard--4 { --owncolor: #{$c-orange}; }
.advcard--5 { --owncolor: #{$c-brown}; }
.advcard--6 { --owncolor: #{$c-bronze}; }


.advcard {
    position: relative;
    background-color: $cl-gray-dark;
    background-color: var(--owncolor);

    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding:7.5% 7.5% 7.5%;
    border-radius: 1.75em;
    border-bottom-right-radius: 0;
    min-height: 15rem;
    margin:0 auto;
    box-shadow: 0 .5rem 1rem -.5rem rgba(#000,.5);

    font-size: .875rem;

    @include breakpoint($bp-large) {
        font-size: 1rem;
    }

    &.even {
        border-bottom-right-radius: 1.75em;
        border-bottom-left-radius: 0;

    }
    &:after {
        content: '';
        position: absolute;
        display: block;
        bottom:0;
        left:0;
        width:100%;
        height:.75em;
        background-image: linear-gradient(90deg,  rgba(#000,0) 3em, rgba(#000,.5) 100%);
        mix-blend-mode: overlay;
    }
    &.even:after {
        background-image: linear-gradient(-90deg, rgba(#000,0) 3em, rgba(#000,.5) 100%);
    }
}

.advcard__content {
    // flex-basis: 85%;
    h3{
        line-height: 1.2;
        font-size: 1.5em;
        margin: 0 0 2.5% 0;
        padding:0 0 2.5%;
        border-bottom: 2px dashed currentColor;
        text-transform: uppercase;

    }

    ul, p {
        clear: both;
        font-size: 1em;
        font-weight: $fw-medium;
    }
    strong {
        font-weight: $fw-bold;
    }
}

.advcard__ill {
    position: relative;
    margin:0;
    padding: 0;
    min-width: 3.5em;
    max-width: 3.5em;
    height:3.5em;
    background-color: #fff;

    border-radius: 50%;
    box-shadow: 0 0 0 .333em $cl-gray-dark;
    box-shadow: 0 0 0 .333em var(--owncolor);
    transform: translateX(-100%) scale(2);
    order:-1;
    overflow: hidden;
    text-align: center;


    .even & {
        order:2;
        transform: translateX(100%) scale(2);
    }

    img {
        position: absolute;
        left:50%;
        top:50%;
        transform: translateX(-50%) translateY(-50%);
        width:70%;
        height:70%;
        object-fit: contain;

        // border-radius: 50%;
        // display: block;
        // border:1em solid $cl-gray-dark;
    }

}

.advcardgrid {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0;
    display: block;
    @include clearfix();

    > li {
        display: block;
        margin:0;
        padding: rem-calc($layout-gutter-width) 0 rem-calc($layout-gutter-width) 12%;
        &:nth-child(2n) {
            padding: rem-calc($layout-gutter-width) 12% rem-calc($layout-gutter-width) 0;

        }
        @include breakpoint($bp-large) {
            padding: rem-calc($layout-gutter-width*1.5) 0;
            width: calc(50% - #{rem-calc($layout-gutter-width*1.5)}) ;
            float: left;
            
            &:nth-child(2n) {
                float:right;
                padding: rem-calc($layout-gutter-width*1.5) 0;
            }
            &:nth-child(2) {
                margin-top: rem-calc($layout-gutter-width*6);
            }                        
        }
    }
}
