.promocard {
    background-color: $cl-gray-dark;

    color: $white;
    text-shadow: 0 0 1.5rem rgba(#000,.333);
    text-align: center;
    height:100%;
    position: relative;
    padding: 20% 0;

    &.promocard--compact {
        padding: 10% 0;
    }
}



.promocard__fig {
    margin:0;
    padding:0;
    height: 100%;
    width:100%;
    left:0;
    top:0;
    position: absolute;
    background-color: #757575;

    img {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height: 100%;
        object-fit: cover;
        mix-blend-mode: multiply;
    }
}

.promocard__content {
    padding:0 7.5% 5rem 7.5%;
    // padding-bottom: 5.5rem;
    height: 100%;
    position: relative;
    z-index: 1;
}



.promocard__title {
    margin:.25em 0 .125em 0;
    font-size: 2.75rem;
    text-transform: uppercase;
    word-wrap: break-word;
    a {
        text-decoration: none;
        &:hover, &:focus {
            color: $cl-primary;
            // color:$white;
        }
    }


    .promocard--compact &{
        font-size: 2.25rem;
        @include breakpoint($bp-xlarge) {
            // font-size:2.75rem;
        }
    }
}

.promocard__excerpt {
    font-size: .9375rem;
    font-weight: $fw-bold;


}

.promocard__actions  {
    position: absolute;

    left:1rem;
    right:1rem;
    bottom:1rem;
    // text-align: right;

    .button {
        font-size: .875rem;
        margin:0;
        .promocard--compact &{
            font-size: .875rem;
        }
    }
}

.triadwrap {
    list-style: none;
    margin: 0 0;
    padding: rem-calc($layout-gutter-width / 2 )  0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    grid-column-gap: rem-calc($layout-gutter-width / 2 );
    grid-row-gap: rem-calc($layout-gutter-width / 2);
    grid-auto-rows: 1fr;

    &.black {
        background-color: $black;
    }

    @include breakpoint($bp-large) {
        grid-column-gap: rem-calc($layout-gutter-width);
        grid-row-gap: rem-calc($layout-gutter-width);
        // grid-template-columns: repeat(2, 1fr);

    }

    @include breakpoint($bp-xlarge) {
        // grid-column-gap: rem-calc($layout-gutter-width * 1.5);
        // grid-template-columns: repeat(3, 1fr);

    }

    > li {
    }
}


.quadwrap {
    list-style: none;
    margin: 0 0;
    padding: rem-calc($layout-gutter-width / 2 )  0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    grid-column-gap: rem-calc($layout-gutter-width / 2 );
    grid-row-gap: rem-calc($layout-gutter-width / 2);
    grid-auto-rows: 1fr;

    &.nowrapper {
        background-color: $cl-black;
    }

    @include breakpoint($bp-large) {

        // grid-template-columns: repeat(3, 1fr);
        grid-template-columns: repeat(auto-fill, minmax(18rem, 1fr));
        grid-column-gap: rem-calc($layout-gutter-width / 1.5 );
        grid-row-gap: rem-calc($layout-gutter-width / 1.5);

    }

    @include breakpoint($bp-xlarge) {
        // grid-template-columns: repeat(4, 1fr);

    }

    > li {
    }
}
