.paramlist {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width:100%;
    list-style: none;
    margin:0;
    padding:0;
    overflow: auto;
    gap:2%;
    // background-color: $cl-gray-xlight;
    height: $paramblockheight;

    @include breakpoint($bp-xxxlarge) {
        font-size: 1.125rem;
    }




    > li {
        // flex-shrink: 1;
        flex-basis: 8.5em;
        margin:0 0 0 0;
        padding:0;
    }
}

.paramcard {
    justify-content: center;
    display: flex;
    flex-direction: column;

    text-align: center;

    // border: 1px solid rgba(#757575,.125);



    img, svg {
        width: 3.5em;
        height:2.125em;
        display: block !important;
        margin:0 auto .5em;
        fill: $cl-gray;
    }

    h3 {
        border-bottom: 1px solid rgba(#757575,.25);
        padding-bottom: .5em;
        font-size: .666em;
        text-transform: uppercase;
        margin:0 0 .5em;
        font-weight: $fw-black;
        color:$cl-gray;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    p {
        margin:0;
        font-size: .75em;
        font-weight: $fw-bold;
        line-height: 1.2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

    }
}
