.swipegallery {
    list-style: none;
    line-height: 1;
    margin:2em 0 2em 0;
    padding:0;

    .slick-list {
        overflow: visible;
    }

    figure {
        margin:0;
        padding: 0;
        // border-left: 1px solid #000;
        border-right: 1px solid rgba(#000,1);
        background-color: #555;
        transition: all .5s linear .3s;

        // border-right: 1px solid $cl-gray-xlight;

    }

    .slick-current figure{
        background-color: #fff;
        // transition: all .3s ease-in-out 0s;
    }

    img {
        height: 48vw;
        mix-blend-mode: multiply;
        display: block;
        width:auto;
        outline: none;

        @include breakpoint($bp-tablet) {
            height: calc((100vw - #{$globalsidebarwidth})*0.5);
            max-height: 75vh;
        }
        // @include breakpoint($bp-xlarge) {
        //     height: calc((100vw - #{$globalsidebarwidth})*0.4);
        // }
    }
}
