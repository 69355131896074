.postcard {
    background-color: $white;
    color: $cl-body-font-color;
    padding:0 10% 15% 10%;
    height:100%;
    position: relative;
}


.postcard__fig {
    margin:0 -12.5%; 
    padding:0;
    height: 0;
    padding-bottom: 75%;
    position: relative;

    img {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        height: 100%;
        object-fit: cover;
    }
}

.postcard__meta {
    position: relative;
    margin-top:-.75rem;

}
.postcard__title {
    margin:5% 0 .25em 0;
    font-size: 1.333rem;
    // font-weight: $fw-bold;
    text-transform: uppercase;
    a {
        text-decoration: none;
        &:hover, &:focus {
            color: $cl-primary;
            // color:$white;
        }
    }

}

.postcard__excerpt {
    font-size: .875rem;
    margin-bottom:1rem;

}

.postcard__actions  {
    position: absolute;

    left:10%;
    right:10%;
    bottom:5%;
    // text-align: right;

    .button {
        font-size: .666rem;
        margin:0;
    }
}

.postcardgrid {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20.5rem, 1fr));
    grid-column-gap: rem-calc($layout-gutter-width * 1);
    grid-row-gap: rem-calc($layout-gutter-width * 1);
    grid-auto-rows: 1fr;


    > li {
        // background-color: $cl-primary;
        // background-color: $cl-gray-xlight;
        // padding:1rem;
        // color: #fff;
        // color: $cl-body-font-color;
    }

    &.onlythree {
        > li:nth-child(n + 4) {
            display: none;
        }

    }
}

