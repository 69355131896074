$cwibgmaxwidth:60rem;
.cwibg {
    position: relative;
    color:#fff;
}
.cwibg__fig  {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-image: linear-gradient(45deg,#333 10%, #fff 60%);
    z-index: -1;
    margin:0;
    padding:0;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: none;
        object-fit: cover;
        mix-blend-mode: multiply;
        // opacity: 0;
    }
}

.cwibg__content {
    @extend .pagewrap;
    text-shadow: 0 0 1.5rem rgba(#000,.333);
    // color:#fff;
    // color: #fff;
    // padding-top:30%;
    // padding-bottom: 0;
    // @include breakpoint(95em) {
    //     padding-left: 7.5%;
    //     padding-right: 7.5%;
    // }

    @include breakpoint($bp-large) {
        // padding-top:15%;
    }
    @include breakpoint($bp-xlarge down) {
        font-size:.9375rem;
    }

    @include breakpoint($bp-tablet down) {
        font-size:.75rem;
    }
}

.cwibg__title {
    font-size: 2.666em;
    font-weight: $fw-black;
    max-width: $cwibgmaxwidth;
    margin:0 0;
    text-transform: uppercase;
}

.cwibg__subtitle {
    font-size:1.75em;
    font-weight: $fw-normal;
    max-width: $cwibgmaxwidth;
}








