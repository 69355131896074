::-webkit-input-placeholder {
    font-weight: $fw-bold;
}

:-ms-input-placeholder {
    font-weight: $fw-bold;
}

::-ms-input-placeholder {
    font-weight: $fw-bold;
}

::placeholder {
    font-weight: $fw-bold;
}


select {
    width:100%;
    margin:0;
}

.checkboxlabel {
    line-height: 1.2;
    font-weight: $fw-normal;
    font-size: 1rem;
    > input {
        float:left;
        margin:.125em .5em 0 0;
    }

    &.small {
        font-weight: $fw-normal;
        font-size: .875rem;
    }

    & + & {
        margin-top:.5em;
    }
}

.formactions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: rem-calc($layout-gutter-width/2) 0;
    > .button {
        // font-size: .875rem;
        min-width: 20%;
        &:first-child {
            flex-grow: 2;
        }
        &:last-child:not(:first-child) {
            margin-left: rem-calc($layout-gutter-width/2);
        }
    }
}

.formresults {
    font-size: .9375rem;
    text-align: center;
    font-weight: $fw-bold;
    color: #fff;

    p {
        margin:0;
        &.itsnotok {
            // color:$cl-primary;
        }
    }
    
}
.viaform {
    margin: 0 auto;
    // max-width: 31rem;

    [type="color"],
    [type="date"],
    [type="datetime-local"],
    [type="datetime"],
    [type="email"],
    [type="month"],
    [type="number"],
    [type="password"],
    [type="search"],
    [type="tel"],
    [type="text"],
    [type="time"],
    [type="url"],
    [type="week"],
    textarea {
        margin: 0;
        &.is-invalid-input {
            // border-color: $cl-primary;
        }
    }
    fieldset {
        margin: rem-calc($layout-gutter-width/2) 0;
    }
    legend {
        font-weight: bold;
        font-size: 1rem;
        font-family: $fontsans;
        // letter-spacing: 0.05em;
        margin: 0 0 0;
    }
    label {
        // letter-spacing: 0.05em;

    }
    &.ondark label {
        color: $cl-gray-xlight;
    }
}

.formsteps {
    list-style: none;
    margin: 0;
    padding: 0;
}

.formstep {
    display: block;
    margin: 0 0;
    padding: 1.75rem 0;

    // &:not(:last-child) {
    //     border-bottom: 1px solid $cl-primary-light;
    // }
}

.formstep__title {
    font-size: 1.25rem;
    text-transform: uppercase;
    // color: inherit;
    // letter-spacing: 0.05em;
    margin: 0 0 0.75rem;
}

.checkergrid {
    list-style: none;
    margin: 0 -0.25rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    > li {
        display: inline-block;
        margin: 0.25rem 0.25rem;
        padding: 0;
        flex-basis: 15rem;
        flex-grow: 1;
    }
}

.inputgrid {
    list-style: none;
    margin: 0 -0.25rem;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    > li {
        display: inline-block;
        margin: 0.25rem 0.25rem;
        padding: 0;
        flex-basis: 12rem;
        flex-grow: 1;
    }
    &.strict {
        > li {
            flex-basis: 100%;
            margin: 0.5rem 0.25rem;
        }
    }
    &.wider {
        > li {
            flex-basis: 24.5rem;
        }
    }
}

.checker {
    input[type="radio"] {
        display: none;
    }
    label {
        background-color: $cl-gray-xlight;
        color: $cl-body-font-color;
        font-size: 1.125rem;
        padding: 0.75em 0.5em 0.5em;
        line-height: 1;
        margin: 0;
        text-align: center;
        text-transform: none;
        white-space: nowrap;
        width: 100%;
        letter-spacing: normal;
        border: 1px solid rgba(#000, 0.125);

        &:hover {
            background-color: $cl-primary;
            color: #fff;
        }
    }

    input[type="radio"]:checked + label {
        background-color: $cl-primary;
        color: #fff;
    }
}


#customamount {
    display: none;
    margin:0 0;
    // &.is-visible {
    //     display:inline-block;
    // }
}

/***   Helpform ***/
.incidentdiscl, .paymentdiscl {
    font-size: .75rem;
    background-color: #fff;
    border: 1px solid $cl-gray-xlight;
    padding:.5rem;
    margin:-.5rem 0 1.875rem;

    > *:last-child() {
        margin-bottom:0;
    }
}

.paymentdiscl {
    @extend .incidentdiscl;
    margin:0 0 0;

}


[type="checkbox"] {
    &.mce_inline_error {
        ~ .form-error {
            display: block;
            color: $cl-primary;
        }
    }
}

.form-error {
    // font-family: $fontmono;
    // letter-spacing: normal;
    // font-weight: $fw-normal;
    margin-top: .25em;

    margin-bottom: $form-spacing / 1.5;
    // font-style: italic;
    line-height: 1.2;
    &.is-visible {
        display: block;
    }
}



.form-helper {
    @extend .form-error;

    display: block;
    color: $medium-gray;
}

form .result {
    font-family: $fontsans;
    font-weight: $fw-bold;
    font-size: .9375rem;
    text-align: center;
    margin:1em 0 0;
    p {
        margin:0 0 .25em;
        line-height: 1.2;
        padding:.5em;
        background-color: $cl-gray-xlight;
        // &.itsnotok {
        //     color: $cl-primary;
        // }
    }
}

.contactmodal {
    background-color: rgba(#fff,1);
    mix-blend-mode: screen;
    color:$cl-body-font-color;
    padding:5% 7.5%;
    max-width: 50rem;
    margin:0 auto;
}
