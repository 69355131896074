h1,h2,h3,h4,h5,h6 {
    font-family: $fontsans;
    font-weight: $fw-black;
    line-height: 1.2;
    margin: 0 0 .75rem;
    span {
        color:$cl-primary;
    }
}
ul,ol {
    margin:0;
    padding:0;
}

h2 {
    font-size:2rem;
    @include breakpoint(768px) {
        font-size:2.5rem;
    }
}
p {
    margin:0 0 1em;
}

a {
    color: inherit;
    &:hover, &:focus {
        // color: $color-blue;
    }
}


ul.niceul {
    @include niceul;
}

.bodycopy {
    ul:not(.swipegallery):not(.slick-dots):not(.tabalikemenu){
        @include niceul;
    }
    ol {
        @include niceol;
    }

    p {
        margin: 0 0 1em 0;
    }

    a:not(.button) {
        text-decoration: underline;
        text-decoration-color: $cl-primary;
    }

    .button {
        font-size: .875em;
    }
}

.contacts {
    > p {
        position: relative;
        padding-left: 3rem;
        //text-indent: -3rem;
        .icon {
            font-size: 1.666em;
            position: absolute;
            width: 2rem;
            margin-left: -3rem;
            //margin-top: -.125rem;
        }

        a {
            text-decoration: none;
        }
    }
}

p.attribution {
    a {
        text-decoration: none;
        font-weight: $fw-bold;
        // color:$color-blue;
        &:hover,&:focus {
            text-decoration: underline;
        }
    }
}

.button {
    display: inline-block;
    margin: 0 0 0.4333rem;
    padding: .875em 1.25em .875em;
    border-radius: 2em;
    min-width: 13em;
    color: $white;
    background-color: $cl-primary;
    // border-radius: 0.333em;
    text-decoration: none;
    font-weight: $fw-black;
    // letter-spacing: 0.05em;
    // text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
    font-size: 1rem;
    border: 1px solid transparent;
    // box-shadow: 0em .5em .25em -.5em rgba(#000,.75);

    &:hover,
    &:focus {
        background-color: darken($cl-primary,10%);
        color: #fff;
    }

    &.secondary {
        background-color: $cl-secondary;
        &:hover,
        &:focus {
            background-color: darken($cl-secondary, 10%);
        }
    }
    &.tertiary {
        background-color: $cl-tertiary;
        &:hover,
        &:focus {
            background-color: darken($cl-tertiary, 10%);
        }
    }

    &.hollow {
        background-color: rgba(#757575, 0.125);
        border-color: currentColor;
        // color: $cl-body-font-color;

        &:hover,
        &:focus {
            background-color: rgba(#757575, 0.5);
            // border-color: $cl-secondary;
            // color: $cl-primary;
        }
    }

    &.faux {
        background-color: transparent;
        // font-weight: normal;
        // text-transform: none;
    }

    &.dark {
        background-color: $cl-gray-dark;


        &:hover,
        &:focus {
            background-color: darken($cl-gray-dark, 10%);
        }
    }

    &.light {
        background-color: $cl-gray-light;
        // color: $cl-body-font-color;

        &:hover,
        &:focus {
            background-color: darken($cl-gray-light, 10%);
        }
    }

    &.tiny {
        font-size: .666rem;
        padding: 0.666em .75em 0.666em;
    }

    &.small {
        font-size: .9375rem;
    }

    &.large {
        font-size: 1.25rem;
    }
}

ul.bulletlist {
    list-style: none;
    margin:0;
    padding:0;
    list-style: none;
    font-size: 1.125rem;
    font-weight: $fw-black;
    line-height: 1.4;

    @include breakpoint($bp-tablet) {
        font-size: 1.4333rem;
    }

    > li {
        margin:0;
        padding:0;
        padding-left: 1.125em;
        position: relative;
        &:not(:last-child) {
            margin-bottom:.75em;
        }

        &:before {
            content: '';
            position: absolute;
            left:0;
            top:.4333em;
            width:.5em;
            height: .5em;
            // border-radius: 50%;
            background-color: $cl-primary;
        }
    }
}


.sectitle {
    color:$cl-primary;
    font-weight: $fw-black;
    line-height: 1.2;
    font-size: 1.875rem !important;
    margin: 0 0;
    text-transform: uppercase;
    @include breakpoint($bp-tablet) {
        font-size: 2.5rem !important;
    }

    @include breakpoint($bp-large) {
        font-size: 2rem !important;
    }

    @include breakpoint($bp-xlarge) {
        font-size: 3rem !important;
    }

    .accent & {
        color: inherit;

    }
    .primaryvar & {
        color: $cl-white;

    }

    span {
        font-weight: inherit;
        color:$cl-body-font-color;

        &.thin {
            font-weight: $fw-normal;
            color: $cl-gray-light;
        }
    }

    small {
        font-size: .666em;
    }
    > a {
        text-decoration: none;
        &:hover, &:focus {
            color: lighten($cl-primary,5%);
            span {
                color:#fff;
            }
        }
    }



    &.withlinea {
        // margin-bottom: 0;
        &:after {
            transform: translateY(-.025em);
            content:'';
            display:block;
            border-top: 1px solid rgba(#000, 0.25);
            border-bottom: 1px solid rgba(#fff, 0.25);
        }
    }
}

hr {
    margin: 1rem auto;
    max-width: rem-calc($layout-content);
    height: 0;
    border: none;
    border-top: 2px solid rgba(#000, 0.25);
    border-bottom: 2px solid rgba(#fff, 0.25);

    &.fulldivider {
        max-width: none;
        margin: 0 auto;
        border-top: 2px solid rgba(#000, 0.05);
        border-bottom: 2px solid rgba(#fff, 0.05);
    }
}

.catlist, .post-categories {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin:0;
    padding:0;
    font-size: .666rem;
    line-height: 1.2;
    > li {
        margin:.125em;
        padding:0;
        white-space: nowrap;
        &:first-child {
            margin-left: 0;
        }

        a {
            display: inline-block;
            background-color: $cl-gray-dark;
            border-radius: 1em;
            padding:.25em .75em;
            letter-spacing: .025em;
            color: $white;
            font-weight: $fw-black;
            text-transform: uppercase;
            text-decoration: none;
            &:hover, &:focus {
                background-color: $cl-primary;
                color:$white;

            }

        }
    }
}

ul.inlinelist {
    list-style: none;
    margin:0;padding:0;
    > li {
        display: inline;
        &:not(:last-child):after {
            // content: ' | ';
            content: ' · ';
        }
    }
}

.accentcopy {
    font-size: 1.125rem;

    &.narrow {
        max-width: 50rem;
    }

    ul {
        @include niceul;
    }

    ol {
        @include niceol;
    }

    a:not(.button) {
        text-decoration: underline;
        text-decoration-color: $cl-primary;
    }


}


.sociallist {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 0;
    padding: 0;
    gap: .25rem;
    > li {
        display: inline-block;
    }
}

.sharelist {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    > li {
        display: inline-block;
        &:not(:last-child()) {
            margin-right: 0.25em;
        }
        &:first-child() {
            margin-right: auto;
        }
    }
}


.sharebtn {
    display: flex;
    align-items: center;
    text-align: center;
    width: 2.25em;
    height: 2.25em;
    font-size: 0.875rem;
    line-height: 1;
    color: $cl-gray-light;
    background-color: $cl-gray-xlight;

    &:hover,
    &:focus {
        background-color: $cl-primary;

        svg {
            fill:#fff;
        }
    }

    svg {
       width:56.666%;
       height:56.666%;
       margin:0 auto;
       fill: $cl-body-font-color;
    }
}

.socialbtn {
    @extend .sharebtn;
    background-color: $cl-gray;
    font-size: .75rem;
    &:hover,
    &:focus {
        // background-color: $cl-primary;

        // svg {
        //     fill:#fff;
        // }
    }

    svg {
        fill: $cl-gray-xlight;
    }

}


svg.icon {
    width: 1em;
    height: 1em;
    fill: currentColor;
    display: inline-block
}


.rentavan {
    font-weight: $fw-black;
    text-transform: uppercase;
    white-space: nowrap;
    span {
        display: block;
        line-height: 1.075;
    }
    .egy {
        font-size: 1.9375em;

    }
    .ketto {
        font-style: italic;
        letter-spacing: .025em;
    }
}

.ishape {
    text-transform: uppercase;
    font-weight: $fw-bold;
    transform: rotate(90deg) scaleX(1.333) translateX(-.05666em) translateY(-.025em);
    display: inline-block;
}