.sitemheaderwrap {
    height:#{$globalheaderheight-small};
    @include breakpoint($bp-tablet) {
        display:none;
    }
}
.headroom.sitemheader {
    will-change: transform;
    transition: transform .4s linear;
    z-index: 1;
    position: fixed;
    left: 0;
    right:0;
    top:0;
    body.admin-bar & {
        top: 32px;
        @media screen and (max-width: 782px) {
            top: 46px;
        }
    }

    &--not-top {
    }

    &--pinned {
    }

    &--unpinned {
        transform: translateY(-$globalheaderheight-small);
    }

    &--top {
        &.sitemheader--unpinned {
            transform: translateY(0) !important;
        }
    }



}

.sitemheader {
    // @extend .pagewrap;
    color:$cl-body-font-color;
    background-color: $cl-body-background;
    position: relative;
    height:#{$globalheaderheight-small};
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 .5rem 0 rgba(#000,.125);
    // margin-left: -$globalsidebarwidth;
    padding-left:3%;
    @include breakpoint($bp-medium) {
        padding-left: 5%;
    }
    @include breakpoint(tablet) {
        display:none;
    }

}



.sitemheader__promo {
    margin-left: auto;
    height:100%;
    align-self: flex-end;

}

.sitemheader__toggler {
    display: flex;
    text-align: center;
    align-items: center;
    padding:.25em .25em;
    // justify-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $cl-gray-xlight;
    color:$cl-primary;
    border:none;

    border-left: 1px solid darken($cl-gray-xlight,2.5%);
    min-width:#{$globalheaderheight-small};
    font-size: .66rem;
    height:#{$globalheaderheight-small};
    text-decoration: none;
    font-weight: $fw-black;
    text-transform: uppercase;
    outline: none;
    &:hover,&:focus {
        background-color: $cl-primary;
        color: $white;
        // box-shadow: 0 2px 0 -1px $cl-primary;
    }

    svg {
        display: block;
        margin:0 auto 0 auto;
        width:2.25em;
        height: 2.75em;
        fill: currentColor;

    }
    span {
        display: none;

    }

    &.secondary {
        margin-left:auto;
        background-color: $cl-black;
        text-transform: none;

        svg {
        }
        span {
            display: block;
            font-size:.875em;
            padding:0 1em;


        }
    }

}

.sitemheader__brand {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: inherit;
    // width: $globalsidebarwidth;
    flex-shrink: 0;


    img {
        height:66%;
        margin:0 0 0 0;

    }
}

.sitemheader__nav {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-items: flex-end;
    position: relative;
}


.sitemheader__topinfo {
    font-family: sans-serif;
    position: absolute;
    right: 4rem;
    top:.4333rem;
    height:1rem;
    line-height: 1.2;
    padding:0 .75rem 0 0;
    font-size: .75rem;
    font-weight: $fw-bold;
    // color: $cl-gray;
    font-feature-settings: "lnum" 0;
    text-transform: uppercase;
    a {
        text-decoration: none;
        // color:$cl-primary;
    }
}


.sitemheader__cta {
    @extend .siteheader__cta;
    padding:.75em 1.5em;
    // margin-left: auto;
    // font-size: .75rem;
}