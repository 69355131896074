.sidemenu {
    position: relative;
    margin:0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-content: stretch;


    color: $cl-gray-light;
    text-transform: uppercase;
    font-weight: $fw-black;
    text-align: center;
    font-size: .5625rem;
    transition: transform .2s ease-out;

    @include breakpoint($bp-tablet){
        font-size: .666rem;
    }
    &.is-open {
        transform: translateX(-100%);
    }

    li {
        // flex-grow: 1;
        // border-bottom: 1px solid rgba(#fff,.125);
        &:not(:last-child) {
            // border-bottom: 1px solid rgba(#fff,.125);
            &:after {
                display: block;
                content: '';
                width:calc(100% - 2.5em);
                height: 1px;
                margin:0 auto;
                background-color: rgba(#fff,.125);
            }
        }
        a {


            display: block;
            text-decoration: none;
            padding:1em 5% 1em 5%;
            width:100%;
            @include breakpoint($bp-tablet){
                padding:1.25em 5% 1.25em 5%;
            }
            &:hover, &:focus {
                background-color: $cl-primary;
                color: #fff;
                box-shadow: 0 -2px 0 -1px $cl-black, 0 2px 0 -1px $cl-black;
                &:after {
                    color:#fff !important;
                }
                svg {
                    fill:#fff;
                    opacity: 1;
                }
            }
            svg {
                height: 2.125em;
                width:100%;
                max-width: 6em;
                display: block;
                margin: 0 auto .5em;
                fill: $cl-gray-light;
                opacity: .75;
                @include breakpoint($bp-tablet){
                    // height: 3em;
                    // width:5em;
                    max-width: 7.5em;
                }
            }
        }
        &.is-active > a {
            background-color: $cl-primary;
            color:#fff;
            box-shadow: 0 -2px 0 -1px $cl-black, 0 2px 0 -1px $cl-black;
            &:after {
                color:#fff !important;
            }
            svg {
                fill: #fff;
            }
        }
        &.has-submenu > a {
            position: relative;
            &:after {
                color: transparent;
                content:"\232A";
                display: inline-block;
                position: absolute;
                top:50%;
                right:2.5%;
                transform: translateY(-50%);
            }
        }
        &.is-accent > a {
            background-color: $cl-gray-xdark;
            color:#fff;
            box-shadow: 0 -2px 0 -1px $cl-black, 0 2px 0 -1px $cl-black;
            &:hover, &:focus {
                background-color: $cl-primary;
                color: #fff;
            }
        }

    }

}

.mobilemenu {
    // @include niceul;
    // border-top:1px solid $cl-gray-xlight;
    border-bottom:1px solid $cl-gray-xlight;
    position: relative;
    margin:0 0;
    padding:.875em 0 1em;
    list-style: none;
    display: flex;
    flex-direction: column;
    color:inherit;
    text-transform: uppercase;
    font-weight: $fw-black;
    font-size: 1.333rem;
    text-align: left;
    line-height: 1.2;

    li {
        a {
            display: inline-block;
            padding:.5666em 0;
        }
    }
}

.mobilesecmenu {
    // @include niceul;
    position: relative;
    margin:0 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    color:inherit;
    // text-transform: uppercase;
    font-weight: $fw-bold;
    font-size: 1rem;
    text-align: left;
    line-height: 1.2;

    li {
        a {
            display: inline-block;
            padding:.5666em 0;
        }
    }
}
.js-closesidemenu {
    position: relative;
    &:before {
        content:"\2329";
        display: inline-block;
        position: absolute;
        top:50%;
        left:5%;
        transform: translateY(-50%);

    }
}

.sidemenu li ul {
    @extend .sidemenu;
    display: none;
    position: absolute;
    top:0;
    left:100%;
    width:100%;
    height: 100%;
}
.sidemenu li.is-active a + ul {
     display: block;
}

.mainmenu {
    margin:0 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: flex-end;

    width:100%;
    margin-right: -.5em;
    font-size: .75rem;
    text-transform: uppercase;
    // letter-spacing: .025em;
    line-height: 1.2;
    font-weight: $fw-bold;

    @include breakpoint($bp-large) {
        font-size: .9375rem;
        margin-right: -.75em;
    }
    @include breakpoint($bp-xlarge) {
        font-size: 1.125rem;
    }
    @include breakpoint($bp-xxxlarge) {
        font-size: 1.25rem;
    }
    li {
        a {
            text-decoration: none;
            padding:.5rem .5em;
            display: inline-block;
            @include breakpoint($bp-large) {
                padding:.5rem .75em;
            }
            &:hover, &:focus {
                color: $cl-primary;
            }
        }
    }
}

.topbarmenu {
    margin:0 0;
    padding: 0;
    list-style: none;
    display: flex;
    // justify-content: space-between;
    justify-content: flex-end;

    width:100%;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: $fw-bold;
    white-space: nowrap;
    letter-spacing: .025em;
    > li {
        a {
            text-decoration: none;
            padding:.125em 0;
            display: inline-block;
            &:hover, &:focus {
                color: $cl-primary;
            }
        }
    }
    >   li:not(:first-child) {
            &:before {
                content: "•";
                content: "|";
                display: inline-block;
                padding:0 .25em 0 .5em;
                color:$cl-gray-light;
                // width: 1em;
                text-align: center;
                // font-weight: $fw-normal;
            }
    }
}


.footmenu {
    @extend .mainmenu;
}

.tabalikemenu {
    list-style:none;
    margin:0 0 0 0;
    padding:0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    font-size:.875rem;
    line-height: 1.2;
    font-weight: $fw-black;
    text-transform: uppercase;
    color: $cl-gray-light;

    &.withlinea {
        // margin-top:-.25em;
        padding-top: .375em;
        border-top: .125em solid rgba(#757575,.3335);
    }

    &.right {
        justify-content: flex-end;
    }

    li {
        padding:.125em 0;
        &:not(:last-child) {
            &:after {
                content:'|';
                display: inline-block;
                margin:0 .333em;
                font-weight: $fw-normal;
                opacity: .5;
            }
        }

        &.tabalikemenu__title {
            // margin-right: auto;
            text-transform: none;
            font-weight: $fw-normal;
            // font-style: italic;
            // margin-right: auto;
            &.eltart {
                margin-right: auto;
            }

            &:after {
                content:':';
                margin: 0 .5em 0 0;
                opacity: 1;
            }
        }


        a {
            color:$cl-primary;
            text-decoration: none !important;
            &:hover, &:focus {
                // text-decoration: underline;
                color:inherit;
            }
        }

        &.is-active, &.current-cat {
            a {
                color:inherit;
            }
        }
    }


}

.footerminimenu {
    list-style:none;
    margin:0 0 0 0;
    padding:0;
    display: block;
    // flex-wrap: wrap;
    // font-size:.875rem;
    line-height: 1.2;
    // font-weight: $fw-black;
    // color: inherit;

    li {
        padding:.125em 0;
        display: inline-block;
        &:not(:last-child) {
            &:after {
                text-align: center;
                content:'|';
                display: inline-block;
                margin:0 .333em;
                // width:1em;
                font-weight: $fw-normal;
                // opacity: .5;
            }
        }
        a {
            &:hover, &:focus {
                // text-decoration: underline;
                color:$cl-primary;
            }
        }

        &.is-active, &.current-cat {
            a {
                color:inherit;
            }
        }
    }


}

.langselmenu {
    margin:0 0;
    padding: 0;
    list-style: none;
    display: flex;
    // justify-content: space-between;
    // justify-content: flex-end;
    width:100%;
    text-transform: uppercase;
    line-height: 1.2;
    font-weight: $fw-bold;
    white-space: nowrap;
    letter-spacing: .025em;
    > li {
        a {
            text-decoration: none;
            padding:.125em 0;
            display: inline-block;
            &:hover, &:focus {
                color: $cl-primary;
            }
        }
    }
    >   li:not(:first-child) {
            &:before {
                content: "•";
                content: "|";
                display: inline-block;
                padding:0 .25em 0 .5em;
                color:$cl-gray-light;
                // width: 1em;
                text-align: center;
                // font-weight: $fw-normal;
            }
    }
}
