// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {





}
.aligncenter {
    // display: block;
    // margin: ($layout-gutter-width / 2) auto;
    // height: auto;
}
.alignleft,
.alignright {
    // margin-bottom: ($layout-gutter-width / 2);
    // height: auto;
}


figure.wp-caption {
    max-width: 100%;
    margin-top:2em;
    margin-bottom:2em;

    [class*="wp-image"] {
        margin-top:0;
        margin-bottom:0;
    }

}

// figure.wp-caption.aligncenter {
//     width: 100%;
// }






[class*="wp-image"] {
    margin-top: 2em;
    margin-bottom: 2em;
    max-width: 100%;
    &.aligncenter, figure.wp-caption.aligncenter & {
        width: 100%;
    }
    &.alignnone, figure.wp-caption.alignnone & {
        width: auto;
        max-width: 100vw;
        margin-left:50%;
        transform:translateX(-50%);
        @include breakpoint($bp-tablet) {
            max-width: calc(100vw - #{$globalsidebarwidth});
        }
    }
}

// Captions
.wp-caption {
    //@extend .figure;
}
.wp-caption > img {
    // @extend .figure-img;
    // @extend .img-fluid;
}
.wp-caption-text {
    // @extend .figure-caption;
}
.wp-caption-text {
    text-align: center;
    font-size: .875em;
    font-style: italic;
    padding: .5em 0 0 0;
    line-height: 1.2;
    color:$cl-gray;
}

// Text meant only for screen readers
.screen-reader-text {
    // @extend .sr-only;
    // @extend .sr-only-focusable;
}


.entry-content-asset {
    position: relative;
    height: 0;
    padding-bottom: 56.25%;
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.stripe {
        height:166px;
        padding-bottom: 0;
    }
}
