
.siteheaderwrap {
    display:none;
    @include breakpoint($bp-tablet) {
        display:block;
    }

    @include breakpoint($bp-large) {
        height:$globalheaderheight;
    }
}
.headroom.siteheader {
    will-change: transform;
    transition: transform .3s linear;
    z-index: 1;
    position: fixed;
    left: 0;
    right:0;
    top:0;

    body.admin-bar & {
        top: 32px;
        @media screen and (max-width: 782px) {
            top: 46px;
        }
    }

    &--not-top {
    }

    &--pinned {
    }

    &--unpinned {
        transform: translateY(-$globalheaderheight);
    }

    &--top {
    }


}

.siteheader {
    display: none;


    @include breakpoint($bp-tablet) {
        display:flex;
        position: relative;
        height:$globalheaderheight-tablet;
        align-items: center;
        background-color: $cl-body-background;
        border-bottom: 1px solid $cl-gray-xlight;
    }

    @include breakpoint($bp-large) {
        height:$globalheaderheight;
    }

}


.siteheader__brand {
    position: relative;
    height:100%;
    width: $globalsidebarwidth;
    background-color: $cl-body-background;
    display: flex;
    align-items: center;
    flex-shrink: 0;

    img {
        height:66.666%;
        margin:0 auto;
    }
}

.siteheader__nav {
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-items: flex-end;
    position: relative;
    height:100%;
    border-right:2px solid $cl-gray-xlight;

}

.siteheader__promo {
    margin-left: 2px;
    height:100%;
}

.rentavancta {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 100%;
    text-transform: uppercase;
    font-weight: $fw-black;
    line-height: 1;
    // white-space: nowrap;
    padding:.125em 1em;
    outline: 1px solid $cl-gray-dark;
    background-color: $cl-gray-dark;
    color:#fff;
    font-size: .666rem;
    letter-spacing: .033em;

    &:hover,&:focus {
        background-color: darken($cl-primary,5%);
        outline: 1px solid darken($cl-primary,5%);
        color: $cl-gray-xlight;
        img {
            // filter: invert(1);
            // mix-blend-mode: multiply;
        }
    }

    span {
        display: inline-block;

        strong {
            font-weight: $fw-black;
            display: block;
            font-size: 2em;
            letter-spacing: normal;
            white-space: nowrap;
        }
    }
}

.siteheader__countryselect {
    margin-left: 1rem;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: $cl-primary;
    width:4rem;
    font-size: .9375rem;
    color:$cl-gray-xlight;
    height:100%;
    outline: 1px solid $cl-primary;
    text-decoration: none;
    font-weight: $fw-black;
    text-transform: uppercase;



    &:hover,&:focus {
        background-color: darken($cl-primary,5%);
        outline: 1px solid darken($cl-primary,5%);
        color: $cl-gray-xlight;
        svg {
            fill:#fff;
        }
    }

    svg {
        display: block;
        margin:0 auto 10% auto;
        width:1.3666rem;
        height:1.3666rem;
        fill:$cl-gray-xlight;
        opacity: .875;

    }
    span {
        display: block;

    }

}



.countrysel-dropdown-pane {
    width:18rem;
}



.siteheader__topbar {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    right: 0;
    top:.5em;
    line-height: 1.2;
    padding-right:.75em;
    font-size: .666rem;


    @include breakpoint($bp-large) {
        font-size: .75rem;
        top:.5rem;
    }

    .siteheader__promo + & {
        right:13.125rem;
    }

}

.siteheader__topbar__nav {

    margin-right: .5rem;
    padding-right: .5rem;
    border-right: 1px solid $cl-gray-light;
}

.siteheader__topbar__actions {
    background-color: $cl-gray;
    color: #fff;
    // font-weight: $fw-black;
    font-weight: $fw-bold;
    // letter-spacing: .125em;
    text-transform: uppercase;
    padding:.25em .5em;

    line-height: 1.2;
    a {
        text-decoration: none;
        // font-family: sans-serif;
        // background-color: $cl-primary;
        // color:$cl-primary;
        &:hover, &:focus {
            color:inherit;
            text-decoration: underline;
        }
    }
}


.siteheader__cta {
    margin: auto .75em;
    text-align: center;
    display: inline-block;
    align-items: center;
    background-color: $cl-primary;
    font-size: .875rem;
    color:$cl-gray-xlight;
    text-decoration: none;
    font-weight: $fw-black;
    text-transform: uppercase;
    letter-spacing: .025em;;
    padding:.875em 1.75em;
    border-radius: 1.75em;

    @include breakpoint($bp-tablet) {
       
    }

    @include breakpoint($bp-large) {
        font-size: .9375rem;
    }



    &:hover,&:focus {
        background-color: darken($cl-primary,5%);
        color: $cl-gray-xlight;
        svg {
            fill:#fff;
        }
    }

    svg {
        display: block;
        margin:0 auto 5% auto;
        width:1.3666rem;
        height:1.3666rem;
        fill:$cl-gray-xlight;
        opacity: .875;

    }
    span {
        display: block;

    }

}

