.mpt {
    @extend .pagewrap;
    padding-top:0;
    position: relative;
    padding-bottom: rem-calc($layout-gutter-width*2.5);
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    align-items: center;


    @include breakpoint($bp-large) {
        padding-bottom: 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
    }

    &.reverse {
        @include breakpoint($bp-large) {
            flex-direction: row-reverse;
        }
    }
}

.mpt__content {
    @extend .bodycopy;
    width:100%;
    margin: rem-calc($layout-gutter-width*2.5) 0 rem-calc($layout-gutter-width);
    z-index: 1;
    .wide &, .xwide &, .full & {
        // font-size: 1.125rem;
        @include breakpoint($bp-large) {
            font-size: 1.125rem;
        }
    }

    @include breakpoint($bp-large) {
        margin: rem-calc($layout-gutter-width*4) 0;
        width:47.5%;
        .wide & {
            width:63%;

        }
        .xwide & {
            width:75%;

        }
        .full & {
            width:100%;

        }
    }
}

.mpt__content {
    h1, h2, h3 {
        font-size: 2em;
        font-weight: $fw-black;
    }

    h4,h5,h6 {
        font-size: 1.5em;
    }
}

.mpt__media {
    width:100%;
    .reverse & {
        direction: rtl;
    }

    @include breakpoint($bp-large) {
       width:47.5%;
       .wide & {
            width:32%;
        }
        .xwide & {
            width:20%;
        }
        .full & {
            width:0;

        }
    }
    @include breakpoint($bp-large) {
    }
}

.mpt__fig {
    position: relative;
    // z-index: 0;
    text-align: center;
    @include breakpoint($bp-large) {
        width:50vw;
        height: 100%;
        .wide & {
            width:33vw;
        }
        .wide & {
            width:25vw;
        }
    }

    img {
        // width:100%;
        // height: 100%;
        // max-width: none;
        // object-fit: cover;
        @include breakpoint($bp-large) {
            position: absolute;
            left:0;
            top:50%;
            transform: translateY(-50%);

            max-height: 100%;
            width:auto;
            max-width: none;
            .reverse & {
                left:auto;
                right:0;
            }
        }

    }


    figcaption {
        position: absolute;
        font-size: .75rem;;
        line-height: 1.2;
        top:100%;
        left:0;
        padding:.333em .5em;
    }
}

.mpt__bg {
    position: absolute;
    z-index: -1;
    right:0;
    bottom:0;
    width:100%;
    mask-image: linear-gradient(180deg, rgba(#000,0) 0, rgba(#000,.5) 100%);
    @include breakpoint($bp-large) {
        width:66.666%;
        height:100%;
        mask-image: linear-gradient(90deg, rgba(#000,0) 0, rgba(#000,1) 66.666%);

        .reverse & {
            right:auto;
            left:0;
            width:50%;
            mask-image: linear-gradient(-90deg, rgba(#000,0) 0, rgba(#000,1) 66.666%);
        }
    }

    img {
        width:100%;
        @include breakpoint($bp-large) {
            object-fit: cover;
            height: 100%;

        }
    }

}

.mpt__kicker {
    text-transform: uppercase;
    letter-spacing: .125em;
    font-weight: $fw-bold;
    font-family: $fontsans;
    font-size: 1.25em;
    line-height: 1.2;
    margin:0 0 .25em;
}
.mpt__title {
    font-size: 2.666em;
    font-family: $fontsans;

    // font-weight: $fw-normal;
}

.mpt__text {
    margin:.5em 0 0;
    font-size: 1.25em;

}


