.widget {
    font-size: 1rem;
    & + & {
        margin-top:1.5rem;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.widget__title {
    text-transform: uppercase;
    line-height: 1.2;
    font-size: 1.333em;
    margin: 0 0 0.333em;
    + * {
        margin-top: 0;
    }
}

.widget__body {

    > *:last-child {
        margin-bottom: 0;
    }
}


.widget--footer {
    font-size: .875rem;
    @include breakpoint($bp-tablet) {
        font-size: .9375rem;
    }
}


.widget--footer {
    ul {
        @include niceul;
        margin-top:0;
        margin-bottom:0;
    }
}
